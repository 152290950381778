import React, { useState } from "react"

const Form = () => {
  const [email, setEmail] = useState("")
  return (
    <div
      id="mc_embed_signup"
      className="w-full my-40 bg-clear px-8 md:px-32 py-40 "
    >
      <h2>We want to revolutionize those lazy days without inspiration.</h2>
      <p className="px-4">
        But we need your <b>help</b>
      </p>
      <p className="text-sm">
        Sign up to be notified when we released closed beta and be the first to
        try
      </p>
      <form
        action="https://gmail.us19.list-manage.com/subscribe/post?u=b39255f3230515ae0c1035794&amp;id=b5204a2cd3"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate content-center"
        target="_blank"
        noValidate
      >
        <div
          id="mc_embed_signup_scroll"
          className="w-auto flex flex-wrap content-center py-2"
        >
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="EMAIL"
            id="mce-EMAIL"
            placeholder="email address"
            required
            className="input-bg email shadow-lg w-3/5 h-12 py-1 px-2 focus:outline-none text-center"
            onClick={e => {
              e.preventDefault()
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: "inputBarClick",
                category: "form",
                action: "click",
                label: "google analytics ",
              })
            }}
          />
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_b39255f3230515ae0c1035794_b5204a2cd3"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>
          <div className="clear  w-2/5">
            <input
              type="submit"
              value="I'm Interested"
              name="I'm Interested"
              id="mc-embedded-subscribe"
              className="button-bg button px-4 py-2 h-12 w-full shadow-md mr-3"
              readOnly
              onClick={e => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "formSubmit",
                  category: "form",
                  action: "click",
                  label: "google analytics ",
                })
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Form
