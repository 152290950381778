import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Figma Plugin Icons
import { Icon } from "@iconify/react"
import leafIcon from "@iconify/icons-entypo/leaf"
import bxsBookBookmark from "@iconify/icons-bx/bxs-book-bookmark"
import bxHomeHeart from "@iconify/icons-bx/bx-home-heart"
import starStruck from "@iconify/icons-twemoji/star-struck"
import robotIcon from "@iconify/icons-twemoji/robot"
import thinkingFace from "@iconify/icons-twemoji/thinking-face"
import droolingFace from "@iconify/icons-twemoji/drooling-face"
import logo from "../images/phone-app2.svg"
import Form from "../components/form"

const IndexPage = () => (
  <Layout>
    <SEO title="Tasty Fridge" />
    <section className=" pl-8 py-8 lg:grid lg:grid-cols-3 lg:gap-8 lg:mb-40 lg:px-24 lg:py-40 bg-gray-100">
      <div>
        <Icon
          icon={bxsBookBookmark}
          style={{ color: "#4F4F4F", fontSize: "64px" }}
          className="mb-4"
        />
        <h2 className="text-lg">Get Fresh Recipe Suggestions</h2>
        <p>
          Use our state of the art AI suggest recipes you can cook with the
          ingredients you already have in your fridge.
        </p>
      </div>
      <div>
        <Icon
          icon={leafIcon}
          style={{ color: "#67B26F", fontSize: "64px" }}
          className="mb-4"
        />
        <h2 className="text-lg">Reduce Food Waste and Be More Eco Friendly</h2>
        <p>
          Throwing away old food is a thing of the past, always get suggested
          food that will turn old first having a Eco friendly option lets you be
          the change in the world you want to be.
        </p>
      </div>
      <div>
        <Icon
          icon={bxHomeHeart}
          style={{ color: "#4F4F4F", fontSize: "64px" }}
          className="mb-4"
        />
        <h2 className="text-lg">Recipes Custom Tailored to Your Likes</h2>
        <p>
          Weather you love rice or have a vegan family member we will always
          remember and suggest you recipes that everyone in your family likes
          and enyos.
        </p>
      </div>
    </section>
    <section className="pr-2 px-8 md:px-32 bg-white py-12 mb-40">
      <div>
        <h2>Simplify your dinner search</h2>
        <p>
          TastyFridge is a recipe suggester that uses artificial intelligence to
          optimize the perfect food recipes for y ou based on what ingredients
          you have right now.
        </p>
      </div>
      <div>
        <h2>A 5 minute dinner or a 3 course meal?</h2>
        <p>
          When time is of the essence you can’t spend all day looking and
          cooking. Get instant recipe suggestions that are tailor made to your
          liking.
        </p>
      </div>
      <div>
        <h2>On a budget or enviroment conscious</h2>
        <p>
          With Tastyfridge it's easy being ECO friendly by choosing recipes and
          ingredients that limit the footprint on our nature.
        </p>
      </div>
    </section>
    <section className="pr-2 px-8 md:px-32 bg-clear-reverse py-12 mb-40">
      <h1 className="content-center">So, How Does It Work?</h1>
      <div className="md:grid md:grid-cols-2">
        <aside>
          <img src={logo} alt="app in action" />
        </aside>
        <aside>
          <div>
            <h2>
              <span>
                <Icon
                  icon={starStruck}
                  style={{ fontSize: "2rem" }}
                  className="inline-block mx-2"
                />
              </span>
              Step 1 - Add Ingredients
            </h2>
            <p className="mx-2">
              Add the ingredients that you want to cook Note: If you already
              have an account your ingredients will be synced automatically
              before and after you cook.
            </p>
          </div>
          <div>
            <h2>
              <span>
                <Icon
                  icon={thinkingFace}
                  style={{ fontSize: "2rem" }}
                  className="inline-block mx-2"
                />
              </span>
              Step 2 - Set Preferences
            </h2>
            <p>
              Choose your food and cooking style, do you want to eat vegan? Low
              carb high fat? Or perhapts you’re in the mood for Mexican style.
            </p>
          </div>
          <div>
            <h2>
              <span>
                <Icon
                  icon={droolingFace}
                  style={{ fontSize: "2rem" }}
                  className="inline-block mx-2"
                />
              </span>
              Step 3 - Pick From Suggestions
            </h2>
            <p>
              You will recieve custom tailored suggestions based on your
              preferences, but it’s up to you to choose what to eat!
            </p>
          </div>
          <div>
            <h2>
              <span>
                <Icon
                  icon={robotIcon}
                  style={{ fontSize: "2rem" }}
                  className="inline-block mx-2"
                />
              </span>
              Step 4 - Give Feedback
            </h2>
            <p>
              Our CookingBot gives you even tastier recipes the more you use
              him, so be sure to let him know what you thought of the recipe.
            </p>
          </div>
        </aside>
      </div>
    </section>
    <section className="content-center">
      <Form />
    </section>
  </Layout>
)

export default IndexPage
